import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
const title = "Société Générale — ";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Choisir sa rente"
    }
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Introduction.vue"),
    meta: {
      title: "Introduction"
    }
  },
  {
    path: "/quizz/:id?",
    name: "Quizz",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Quizz.vue"),
    meta: {
      title: "Questionnaire"
    }
  },
  {
    path: "/resultats",
    name: "Résultats",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Results.vue"),
    meta: {
      title: "Vos résultats"
    }
  },
  {
    path: "/votre-profil/",
    name: "Profil",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Profil.vue"),
    meta: {
      title: "Votre profil"
    }
  },
  {
    path: "/activite/",
    name: "Activité",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Activite.vue"),
    meta: {
      title: "Activité"
    }
  },
  {
    path: "/final/",
    name: "Final",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Final.vue"),
    meta: {
      title: "Final"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.beforeEach((to, from, next) => {
  document.title = title + to.meta.title;
  next();
});

export default router;

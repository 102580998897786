<template>
  <div ref="player" :class="'player ' + size">
    <div v-if="title" ref="title" class="player-title">
      <span>{{ title }}</span>
    </div>
    <div class="video-container" @click="handlePlay">
      <video
        @play="isPlaying = true"
        @pause="isPlaying = false"
        @ended="$emit('ended')"
        ref="video"
        playsinline
        @timeupdate="handleTime"
        :key="videoSrc"
      >
        <source :src="videoSrc" type="video/mp4" />
      </video>
    </div>
    <div ref="controls" class="controls">
      <button
        :class="isPlaying ? 'play' : 'play pause'"
        @click="handlePlay"
        v-if="false"
      ></button>
      <span class="timestamp" v-if="false"
        >{{ displayedTime.minutes }}:{{ displayedTime.seconds }}</span
      >
      <div
        @click="handleMute"
        class="sound"
        :class="isMuted ? 'sound-off' : 'sound-on'"
      ></div>
      <div class="progression">
        <input
          :min="0"
          :max="100"
          :value="progression"
          type="range"
          @change="handleTimeline"
        />
      </div>
      <button
        @click="handleFullscreen"
        :class="isFullscreen ? 'fullscreen full' : 'fullscreen'"
      ></button>
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
// import enableInlineVideo from "iphone-inline-video";

export default {
  data() {
    return {
      isMounted: false,
      isPlaying: false,
      isMuted: false,
      currentTime: 0,
      duration: 0,
      progression: 0,
      isFullscreen: false,
      moving: false,
      videoSrc: ""
    };
  },
  props: {
    video: {
      type: String,
      default: ""
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "small"
    },
    title: {
      type: String,
      required: false
    },
    playinline: {
      type: Boolean,
      default: false
    },
    playing: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    displayedTime() {
      let minutes = Math.floor(this.currentTime / 60);
      let seconds = Math.floor(this.currentTime - minutes * 60);
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;
      return { minutes, seconds };
    }
  },
  mounted() {
    // let self = this;
    // if not IE
    // if (!window.document.documentMode) {
    document.dispatchEvent(new Event("click"));
    // }
    this.isMounted = true;
    this.handleHideShow();
    // if (this.playinline) {
    //   enableInlineVideo(this.$refs.video);
    // }
    if (this.autoplay) {
      this.isPlaying = true;
      setTimeout(() => {
        this.$refs.video.muted = false;
        this.$refs.video.volume = window.document.documentMode ? 0.15 : 0.5;
        console.log("window:", window);
        this.$refs.video.play();
      }, 500);
    }
  },
  methods: {
    handleHideShow() {
      let self = this;
      window.addEventListener("mousemove", () => {
        self.moving = true;
        if (self.$refs.controls)
          gsap.to(self.$refs.controls, { opacity: 1, y: 0, duration: 1 });
        if (self.$refs.title)
          gsap.to(self.$refs.title, { opacity: 1, y: 0, duration: 1 });
      });
      setInterval(() => {
        setTimeout(() => {
          self.moving = false;
          if (self.isPlaying) {
            gsap.to(self.$refs.controls, { opacity: 0, y: 100, duration: 1 });
            if (self.title)
              gsap.to(self.$refs.title, { opacity: 0, y: -100, duration: 1 });
          }
        });
      }, 6000);
    },
    handlePlay() {
      this.$refs.video.volume = window.document.documentMode ? 0.15 : 0.5;
      if (this.isPlaying) this.$refs.video.pause();
      else this.$refs.video.play();
    },
    handleMute() {
      if (this.isMuted)
        this.$refs.video.volume = window.document.documentMode ? 0.15 : 0.5;
      else this.$refs.video.volume = 0;
      this.isMuted = !this.isMuted;
    },
    handleTime() {
      if (this.$refs.video) {
        this.currentTime = this.$refs.video.currentTime;
        this.progression = (this.currentTime / this.$refs.video.duration) * 100;
      }
    },
    handleTimeline(e) {
      if (this.$refs.video) {
        const newTime = (this.$refs.video.duration * e.target.value) / 100;
        this.$refs.video.currentTime = newTime;
      }
      // this.$refs.video.pause();
      // this.$refs.video.play();
    },
    handleFullscreen() {
      console.log("ok");
      if (!this.isFullscreen) {
        this.isFullscreen = true;
        if (this.$refs.video.requestFullscreen) {
          this.$refs.video.requestFullscreen();
          //this.$refs.video.controls = true;
        } else if (this.$refs.video.mozRequestFullScreen) {
          this.$refs.video.mozRequestFullScreen();
        } else if (this.$refs.video.msRequestFullscreen) {
          this.$refs.video.msRequestFullscreen();
          //vid.controls = true;
        } else if (this.$refs.video.webkitSupportsFullscreen) {
          this.$refs.video.webkitEnterFullscreen();
        }
        //this.$refs.video.controls = true;
      } else {
        this.isFullscreen = false;
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    }
  },
  created() {
    this.videoSrc = require("@/assets/videos/" + this.video);
    console.log("this.videoSrc:", this.videoSrc);
  },
  watch: {
    video(newValue) {
      console.log("newValue:", newValue);
      if (newValue) this.videoSrc = require("@/assets/videos/" + newValue);
    }
  }
};
</script>

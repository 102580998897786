import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    score: 0,
    answers: [],
    steps: [],
    profils: [
      {
        name: "Prudent",
        slug: "prudent",
        video: "pp.mp4",
        maxScore: 40
      },
      {
        name: "Équilibre",
        slug: "equilibre",
        video: "pe.mp4",
        maxScore: 70
      },
      {
        name: "Dynamique",
        slug: "dynamique",
        video: "pd.mp4",
        maxScore: 95
      },
      {
        name: "Gestion Libre",
        slug: "gestion-libre",
        video: "gl.mp4",
        maxScore: 300
      }
    ],
    result: {}
  },
  mutations: {
    INCR_SCORE(state, value) {
      state.score += value;
    },
    ADD_ANSWER(state, answer) {
      state.answers.push(answer);
    },
    ADD_STEP(state, step) {
      state.steps.push(step);
    },
    PREVIOUS_STEP(state) {
      let lastAnswer = state.answers.pop();
      state.steps.pop();
      if (Array.isArray(lastAnswer))
        lastAnswer.forEach(answer => (state.score -= answer.score));
      else state.score -= lastAnswer.score;
    }
  },
  getters: {
    score(state) {
      return state.score;
    },
    answers(state) {
      return state.answers;
    },
    steps(state) {
      return state.steps;
    },
    profils(state) {
      return state.profils;
    },
    result(state) {
      let result = {};
      if (state.score <= 40) result = state.profils[0];
      else if (state.score > 40 && state.score < 70) result = state.profils[1];
      else if (state.score >= 70 && state.score <= 115)
        result = state.profils[2];
      else if (state.score > 115) result = state.profils[3];
      return result;
    }
  },
  actions: {
    async updateScore({ commit }, value) {
      commit("INCR_SCORE", value);
    },
    async updateAnswers({ commit }, value) {
      commit("ADD_ANSWER", value);
    },
    async updateSteps({ commit }, value) {
      commit("ADD_STEP", value);
    }
  }
});

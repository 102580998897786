<template>
  <Layout>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </Layout>
</template>
<script>
import Layout from "@/layouts/Layout";
export default {
  components: {
    Layout
  }
};
</script>

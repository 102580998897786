<template>
  <div class="home">
    <div class="content" ref="content">
      <div class="logo-container" v-if="false">
        <img
          ref="logo"
          src="@/assets/images/logo.svg"
          alt="Société Générale Asssurances"
        />
      </div>
      <h1 ref="title">
        Découvrez en 2 minutes la gestion financière qui vous convient le mieux
      </h1>
      <div ref="cta" class="begin-btn">
        <button class="button" @click="handleNext">Démarrer</button>
      </div>
    </div>
    <div class="introduction" :class="step === 2 ? 'show' : 'hide'" ref="intro">
      <Player
        v-show="step === 2"
        video="sga_intro.mp4"
        size="full"
        :playinline="true"
        @ended="handleBegin"
        ref="player"
      />
    </div>
  </div>
</template>
<script>
import gsap from "gsap";
import Player from "@/components/Player.vue";
import { bus } from "../main";
export default {
  components: {
    Player
  },
  data() {
    return {
      step: 1
    };
  },
  mounted() {
    const timeline = gsap.timeline();
    timeline.from([/*this.$refs["logo"],*/ this.$refs["title"]], {
      opacity: 0,
      y: 50,
      // ease: "power2.out",
      stagger: 0.6,
      delay: 0.5
    });
    timeline.from(this.$refs["cta"], { opacity: 0 });
  },
  methods: {
    handleNext() {
      gsap.to(this.$refs.content, { opacity: 0, duration: 1 });
      this.$refs.player.handlePlay();
      this.step = 2;
      gsap.from(this.$refs.intro, { opacity: 0, delay: 1, duration: 1 });
    },
    handleBegin() {
      bus.$emit("playTrack");
      gsap.to(this.$refs.intro, { opacity: 0, duration: 0.8 });
      setTimeout(() => {
        this.$router.push("/quizz/1");
      }, 1000);
    }
  }
};
</script>
